export const RwandaDistrictSector = [
  {
    name: "Gasabo",
    sectors: [
      "Bumbogo",
      "Gatsata",
      "Gikomero",
      "Gisozi",
      "Jabana",
      "Jali",
      "Kacyiru",
      "Kimihurura",
      "Kimironko",
      "Kinyinya",
      "Ndera",
      "Nduba",
      "Remera",
      "Rusororo",
      "Rurunga",
    ],
  },
  {
    name: "Nyarugenge",
    sectors: [
      "Gitega",
      "Kanyinya",
      "Kigali",
      "Kimisagara",
      "Mageragere",
      "Muhima",
      "Nyakabanda",
      "Nyamirambo",
      "Nyarugenge",
      "Rwezamenyo",
    ],
  },
  {
    name: "Kicukiro",
    sectors: [
      "Gahanga",
      "Gatenga",
      "Gikondo",
      "Kagarama",
      "Kanombe",
      "Kicukiro",
      "Kigarama",
      "Masaka",
      "Niboye",
      "Nyarugunga",
    ],
  },
  {
    name: "Huye",
    sectors: [
      "Gishamvu",
      "Huye",
      "Karama",
      "Kigoma",
      "Kinazi",
      "Maraba",
      "Mbazi",
      "Mukura",
      "Ngoma",
      "Ruhashya",
      "Rusatira",
      "Rwaniro",
      "Simbi",
      "Tumba",
    ],
  },
  {
    name: "Gisagara",
    sectors: [
      "Gikonko",
      "Gishubi",
      "Kansi",
      "Kabirizi",
      "Kigembe",
      "Mamba",
      "Muganza",
      "Mugombwa",
      "Mukingo",
      "Musha",
      "Ndora",
      "Nyanza",
      "Save",
    ],
  },
  {
    name: "Nyanza",
    sectors: [
      "Busasamana",
      "Busoro",
      "Cyabakamyi",
      "Kibirizi",
      "Kigoma",
      "Mukingo",
      "Muyira",
      "Ntyazo",
      "Nyagisozi",
      "Rwabicuma",
    ],
  },
  {
    name: "Nyaruguru",
    sectors: [
      "Busanze",
      "Cyahinda",
      "Kibeho",
      "Kivu",
      "Mata",
      "Muganza",
      "Munini",
      "Ngera",
      "Ngoma",
      "Nyabimata",
      "Nyagisozi",
      "Ruheru",
      "Ruramba",
      "Rusenge",
    ],
  },
  {
    name: "Nyamagabe",
    sectors: [
      "Buruhukiro",
      "Cyanika",
      "Gasaka",
      "Gatare",
      "Kaduha",
      "Kamegeri",
      "Kibirizi",
      "Kibumbwe",
      "Kitabi",
      "Mbazi",
      "Mugano",
      "Musange",
      "Musebeya",
      "Mushubi",
      "Nkomane",
      "Tare",
      "Uwinkingi",
    ],
  },
  {
    name: "Muhanga",
    sectors: [
      "Cyeza",
      "Kabacuzi",
      "Kibangu",
      "Kiyumba",
      "Muhanga",
      "Mushishiro",
      "Nyabinoni",
      "Nyamabuye",
      "Nyarusange",
      "Rongi",
      "Rugendabari",
      "Shyogwe",
    ],
  },
  {
    name: "Kamonyi",
    sectors: [
      "Gacurabwenge",
      "Karama",
      "Kayenzi",
      "Kayumbu",
      "Mugina",
      "Musambira",
      "Ngamba",
      "Nyamiyaga",
      "Nyarubaka",
      "Rugarika",
      "Rukoma",
      "Runda",
    ],
  },
  {
    name: "Ruhango",
    sectors: [
      "Bweramana",
      "Byimana",
      "Kabagali",
      "Kinazi",
      "Kinihira",
      "Mbuye",
      "Mwendo",
      "Ntongwe",
      "Ruhango",
    ],
  },
  {
    name: "Rusizi",
    sectors: [
      "Bugarama",
      "Butare",
      "Bweyeye",
      "Gashonga",
      "Giheke",
      "Gihundwe",
      "Gikundamvura",
      "Gitambi",
      "Kamembe",
      "Muganza",
      "Mururu",
      "Nkaka",
      "Nkombo",
      "Nkungu",
      "Nyakabuye",
      "Nyakarenzo",
      "Nzahaha",
      "Rwimbogo",
    ],
  },
  {
    name: "Nyamasheke",
    sectors: [
      "Bushekeri",
      "Bushenge",
      "Cyato",
      "Gihombo",
      "Kagano",
      "Kanjongo",
      "Karambi",
      "Karengera",
      "Kirimbi",
      "Macuba",
      "Mahembe",
      "Nyabitekeri",
      "Rangiro",
      "Ruharambuga",
      "Shangi",
    ],
  },
  {
    name: "Karongi",
    sectors: [
      "Bwishyura",
      "Gishari",
      "Gishyita",
      "Gitesi",
      "Mubuga",
      "Murambi",
      "Murundi",
      "Mutuntu",
      "Rubengera",
      "Rugabano",
      "Ruganda",
      "Rwankuba",
      "Twumba",
    ],
  },
  {
    name: "Rutsiro",
    sectors: [
      "Boneza",
      "Gihango",
      "Kigeyo",
      "Kivumu",
      "Manihira",
      "Mukura",
      "Murunda",
      "Musasa",
      "Mushonyi",
      "Mushubati",
      "Nyabirasi",
      "Ruhango",
      "Rusebeya",
    ],
  },
  {
    name: "Rubavu",
    sectors: [
      "Bugeshi",
      "Busasamana",
      "Cyanzarwe",
      "Gisenyi",
      "Kanama",
      "Kanzenze",
      "Mudende",
      "Nyakiriba",
      "Nyamyumba",
      "Nyundo",
      "Rubavu",
      "Rugerero",
    ],
  },
  {
    name: "Ngororero",
    sectors: [
      "Bwira",
      "Gatumba",
      "Hindiro",
      "Kabaya",
      "Kageyo",
      "Kavumu",
      "Matyazo",
      "Muhanda",
      "Muhororo",
      "Ndaro",
      "Ngororero",
      "Nyange",
      "Sovu",
    ],
  },
  {
    name: "Nyabihu",
    sectors: [
      "Bigogwe",
      "Jenda",
      "Jomba",
      "Kabatwa",
      "Karago",
      "Kintobo",
      "Mukamira",
      "Muringa",
      "Rambura",
      "Rugera",
      "Rurembo",
      "Shyira",
    ],
  },
  {
    name: "Musanze",
    sectors: [
      "Busogo",
      "Cyuve",
      "Gacaca",
      "Gashaki",
      "Gataraga",
      "Kimonyi",
      "Kinigi",
      "Muhoza",
      "Muko",
      "Musanze",
      "Nkotsi",
      "Nyange",
      "Remera",
      "Rwaza",
      "Shingiro",
    ],
  },
  {
    name: "Gakenke",
    sectors: [
      "Busengo",
      "Coko",
      "Cyabingo",
      "Gakenke",
      "Gashenyi",
      "Janja",
      "Kamubuga",
      "Kamubuga",
      "Karambo",
      "Kivuruga",
      "Mataba",
      "Minazi",
      "Mugunga",
      "Muhondo",
      "Muyongwe",
      "Muzo",
      "Nemba",
      "Ruli",
      "Rusasa",
      "Rushashi",
    ],
  },
  {
    name: "Rulindo",
    sectors: [
      "Base",
      "Burega",
      "Bushoki",
      "Buyoga",
      "Cyinzuzi",
      "Cyungo",
      "Kinihira",
      "Kisaro",
      "Masoro",
      "Mbogo",
      "Murambi",
      "Ngoma",
      "Ntarabana",
      "Rukozo",
      "Rusiga",
      "Shyorongi",
      "Tumba",
    ],
  },
  {
    name: "Gicumbi",
    sectors: [
      "Bukure",
      "Bwisige",
      "Byumba",
      "Cyumba",
      "Giti",
      "Kageyo",
      "Kaniga",
      "Manyagiro",
      "Miyove",
      "Mukarange",
      "Muko",
      "Mutete",
      "Nyamiyaga",
      "Nyankenke",
      "Rubaya",
      "Rukomo",
      "Rushaki",
      "Rutare",
      "Ruvune",
      "Shangasha",
      "Rwamiko",
    ],
  },
  {
    name: "Burera",
    sectors: [
      "Bungwe",
      "Butaro",
      "Cyanika",
      "Cyeru",
      "Gahunga",
      "Gatebe",
      "Gitovu",
      "Kagogo",
      "Kinoni",
      "Kinyababa",
      "Kivuye",
      "Nemba",
      "Rugarama",
      "Rugengabari",
      "Ruhunde",
      "Rusarabuye",
      "Rwerere",
    ],
  },
  {
    name: "Rwamagana",
    sectors: [
      "Fumbwe",
      "Gahengeri",
      "Gishali",
      "Karenge",
      "Kigabiro",
      "Muhazi",
      "Munyaga",
      "Munyiginya",
      "Musha",
      "Muyumbu",
      "Mwulire",
      "Nyakaliro",
      "Nzige",
      "Rubona",
    ],
  },
  {
    name: "Kayonza",
    sectors: [
      "Gahini",
      "Kabare",
      "Kabarondo",
      "Mukarange",
      "Murama",
      "Murundi",
      "Mwili",
      "Ndego",
      "Nyamirama",
      "Rukara",
      "Ruramira",
      "Rwinkwavu",
    ],
  },
  {
    name: "Gatsibo",
    sectors: [
      "Gasange",
      "Gatsibo",
      "Gitoki",
      "Kabarore",
      "Kageyo",
      "Kiramuruzi",
      "Kiziguro",
      "Muhura",
      "Murambi",
      "Ngarama",
      "Nyagihanga",
      "Remera",
      "Rugarama",
      "Rwimbogo",
    ],
  },
  {
    name: "Nyagatare",
    sectors: [
      "Gatunda",
      "Karama",
      "Karangazi",
      "Katabagemu",
      "Kiyombe",
      "Matimba",
      "Mimuri",
      "Mukama",
      "Musheri",
      "Nyagatare",
      "Rukomo",
      "Rwempasha",
      "Rwimiyaga",
      "Tabagwe",
    ],
  },
  {
    name: "Kirehe",
    sectors: [
      "Gahara",
      "Gatore",
      "Kigarama",
      "Kigina",
      "Kirehe",
      "Mahama",
      "Mpanga",
      "Musaza",
      "Mushikiri",
      "Nasho",
      "Nyamugari",
      "Nyarubuye",
    ],
  },
  {
    name: "Ngoma",
    sectors: [
      "Gashanda",
      "Jarama",
      "Karembo",
      "Kazo",
      "Kibungo",
      "Mugesera",
      "Murama",
      "Mutenderi",
      "Remera",
      "Rukira",
      "Rukumberi",
      "Rurenge",
      "Sake",
      "Zaza",
    ],
  },
  {
    name: "Bugesera",
    sectors: [
      "Gashora",
      "Juru",
      "Kamabuye",
      "Mareba",
      "Mayange",
      "Musenyi",
      "Mwogo",
      "Ngeruka",
      "Ntarama",
      "Nyamata",
      "Nyarugenge",
      "Rilima",
      "Ruhuha",
      "Rweru",
      "Shyara",
    ],
  },
];
